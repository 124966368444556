import { createUseStyles } from "react-jss";

// Atoms:
import TransitionPage from "../components/atoms/TransitionPage/TransitionPage";
import H3Title from "../components/atoms/H3Title/H3Title";
import Picture from '../components/atoms/Picture/Picture';

const useStyles = createUseStyles({
    about__wrapper: {
        overflow: "auto",
        height: '-webkit-fill-available',
        padding: "1.6rem 26.6rem",
        display: "flex",
        flexDirection: "column",
        gap: "12.8rem"
    },
    about__personelWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "7rem"
    },
    about__personelContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'flex-end',
        gap: "7rem"
    },
    about__meContent: {
        display: "flex",
        justifyContent: "space-between",
        width: '100%'
    },
    about__meYears: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "21.8rem"
    },
    about__paraFirst: {
        fontFamily: "Roboto",
        fontSize: "1.9rem",
        color: "#1F1F1F",
        maxWidth: '32rem'
    },
    about__paraMe: {
        fontFamily: "Roboto",
        fontSize: "1.9rem",
        color: "#1F1F1F",
        position: 'relative',
        "&:before": {
            content: '""',
            position: 'absolute',
            left: '9rem',
            bottom: '.8rem',
            width: '4.8rem',
            height: '0.2rem',
            display: 'block',
            background: '#FFE68C'
        }
    },
    about__paraYears: {
        fontFamily: "Roboto",
        fontSize: "1.9rem",
        color: "#1F1F1F"
    },
    about__paraAboutMe: {
        width: "82rem",
        lineHeight: '2.4rem',
        marginRight: "10rem",
        fontFamily: "Roboto",
        fontSize: "1.9rem",
        color: "#1F1F1F"
    },
    about__worksWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "7rem"
    },
    about__termsWrapper: {
        display: "flex",
        justifyContent: "center",
        "& a": {
            textDecoration: "none"
        }
    },
    about__worksContent: {

    },
    about__worksList: {
        display: "flex",
        gap: "11.6rem",
        justifyContent: "space-between",
        flexWrap: "wrap"
    },
    about__worksEl: {
        display: 'flex',
        flexDirection: 'column',
        gap: '.6rem'
    },
    about__elTitle: {
        fontFamily: "Roboto",
        fontSize: "1.9rem",
        fontWeight: "bold",
        color: '#1F1F1F'
    },
    about__elCate: {
        fontFamily: "Roboto",
        fontSize: "1.6rem",
        color: '#1F1F1F',
        position: 'relative',
        paddingLeft: '5.6rem',
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: '.6rem',
            width: '4.8rem',
            height: '0.2rem',
            display: 'block',
            background: '#1F1F1F'
        }
    },
    about__elYear: {
        fontFamily: "Roboto",
        fontSize: "1.6rem",
        color: '#1F1F1F',
        position: 'relative',
        paddingLeft: '3.2rem',
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: '.6rem',
            width: '2.4rem',
            height: '0.2rem',
            display: 'block',
            background: '#1F1F1F'
        }
    },
    about__elLoca: {
        fontFamily: "Roboto",
        fontSize: "1.6rem",
        color: '#1F1F1F',
        position: 'relative',
        paddingLeft: '2.8rem',
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: '.6rem',
            width: '2rem',
            height: '0.2rem',
            display: 'block',
            background: '#1F1F1F'
        }
    },
    '@media (max-width: 720px)': {
        about__wrapper: {
            padding: '3.2rem'
        },
        about__personelWrapper: {
        },
        about__personelContent: {
        },
        about__meContent: {
            flexDirection: 'column',
            gap: '1.6rem'
        },
        about__meYears: {
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        about__paraMe: {
        },
        about__paraYears: {
        },
        about__paraAboutMe: {
            margin: 0,
            width: '100%',
            fontSize: '2.4rem'
        },
        about__worksWrapper: {
        },
        about__termsWrapper: {
        },
        about__worksContent: {
        },
        about__worksList: {
        },
        about__worksEl: {
        },
        about__elTitle: {
        },
        about__elCate: {
        },
        about__elYear: {
        },
        about__elLoca: {
        },
    }
})

const About = ({data}) => {
    const classes = useStyles();

    const getSection01 = (section) => {
        return(
            <>
                <H3Title title={section?.title?.name} path={section?.title?.path} link={section?.title?.link}/>
                <div className={classes.about__personelContent}>
                    <div className={classes.about__meContent}>
                        <div className={classes.about__meYears}>
                            <p className={classes.about__paraFirst}>Artiste Photographe et Retoucheuse basée en France.</p>
                            <p className={classes.about__paraMe}>{section?.content?.extra?.para01}</p>
                            <p className={classes.about__paraYears}>{(new Date().getFullYear())}</p>
                        </div>
                        <Picture img={section?.content?.imgAboutMe} alt={'Img Maurine Lamotte'}/>
                    </div>
                    <p className={classes.about__paraAboutMe}>{section?.content?.para}</p>
                </div>
            </>
        );
    };
    const getSection02 = (section) => {
        return(
            <>
                <H3Title title={section?.title?.name} path={section?.title?.path} link={section?.title?.link}/>
                <div className={classes.about__worksContent}>
                    <ul className={classes.about__worksList}>
                        {section?.clients?.map((client, key) => (
                            <li className={classes.about__worksEl} key={key}>
                                <h4 className={classes.about__elTitle}>{client?.name}</h4>
                                <p className={classes.about__elCate}>{client?.categorie}</p>
                                <p className={classes.about__elYear}>{client?.year}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </>
        );
    };
    
    const getSection03 = (section) => {
        return(
            <>
                <H3Title title={section?.title?.name} path={section?.title?.path} link={section?.title?.link}/>
                <div className={classes.about__worksContent}>
                    <ul className={classes.about__worksList}>
                        {section?.expos?.map((expo, key) => (
                            <li className={classes.about__worksEl} key={key}>
                                <h4 className={classes.about__elTitle}>{expo?.name}</h4>
                                <p className={classes.about__elCate}>{expo?.event}</p>
                                <p className={classes.about__elYear}>{expo?.date}</p>
                                {expo?.video ? <a href={expo?.video?.link} className={classes.about__elLoca}>{expo?.video?.title}</a> : null}
                            </li>
                        ))}
                    </ul>
                </div>
            </>
        )
    }

    const getSection04 = (section) => {
        return(
            <>
                <H3Title title={section?.title?.name} path={section?.title?.path} link={section?.title?.link}/>
                <div className={classes.about__worksContent}>
                    <ul className={classes.about__worksList}>
                        {section?.credits?.map((credit, key) => (
                            <li className={classes.about__worksEl} key={key}>
                                <h4 className={classes.about__elTitle}>{credit?.name}</h4>
                                <p className={classes.about__elCate}>{credit?.action}</p>
                                <p className={classes.about__elYear}>{credit?.role}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </>
        )
    }

    const getSection05 = (section) => {
        return(
            <H3Title title={section?.title?.name} path={section?.title?.path} link={section?.title?.link}/>
        );
    }

    return(
        <TransitionPage>
            <div className={classes.about__wrapper}>
                <div className={classes.about__personelWrapper}>
                    {getSection01(data?.about?.section01)}
                </div>
                <div className={classes.about__worksWrapper}>
                    {getSection02(data?.about?.section02)}
                </div>
                <div className={classes.about__worksWrapper}>
                    {getSection03(data?.about?.section03)}
                </div>
                <div className={classes.about__worksWrapper}>
                    {getSection04(data?.about?.section04)}
                </div>
                <div className={classes.about__termsWrapper}>
                    {getSection05(data?.about?.section05)}
                </div>
            </div>
        </TransitionPage>
    )
}

export default About;