import { createUseStyles } from 'react-jss';

// Atoms: 
import LinkNavigation from "../../atoms/LinkNavigation/LinkNavigation";
import LinkIconNavigation from "../../atoms/LinkIconNavigation/LinkIconNavigation";
import TitlePage from '../../atoms/TitlePage/TitlePage';

import logo from '../../../assets/icons/logo.png';


const useStyles = createUseStyles({
    navigation__wrapper: {
        display: "flex",
        justifyContent: "space-between",
        padding: "1.6rem",
    },
    navigation__titleWrapper: {
        flex: "1"
    },
    navigation__iconsWrapper: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        gap: "1.6rem"
    },
    navigation__icon: {
        width: "1.6rem",
        height: "1.6rem",
    },
    navigation__aboutWrapper: {
        flex: 1,
        display: "flex",
        justifyContent: "flex-end",
        "& a": {
            fontFamily: "sans-serif",
            textDecoration: "none",
            color: "#1F1F1F"
        }
    },
    '@media (max-width: 720px)': {
        navigation__wrapper: {},
        navigation__titleWrapper: {},
        navigation__iconsWrapper: {
            gap: '2.4rem'
        },
        navigation__icon: {},
        navigation__aboutWrapper: {}
    }
});

const Navigation = (props) => {
    const classes = useStyles();

    return(
        <header className={classes.navigation__wrapper}>
            <div className={classes.navigation__titleWrapper}>
                <TitlePage path={'/'} logo={logo} title={'Maurine Lamotte'}/>
            </div>
            <div className={classes.navigation__iconsWrapper}>
                <div className={classes.navigation__icon}>
                    <LinkIconNavigation path={'/'}>
                        <svg width="1.6rem" height="1.6rem" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="12" width="4" height="4" fill="#1F1F1F"/>
                            <rect y="6" width="4" height="4" fill="#1F1F1F"/>
                            <rect width="4" height="4" fill="#1F1F1F"/>
                            <rect x="6" y="12" width="4" height="4" fill="#1F1F1F"/>
                            <rect x="6" y="6" width="4" height="4" fill="#1F1F1F"/>
                            <rect x="6" width="4" height="4" fill="#1F1F1F"/>
                            <rect x="12" y="12" width="4" height="4" fill="#1F1F1F"/>
                            <rect x="12" y="6" width="4" height="4" fill="#1F1F1F"/>
                            <rect x="12" width="4" height="4" fill="#1F1F1F"/>
                        </svg>
                    </LinkIconNavigation>
                </div>
                <div className={classes.navigation__icon}>
                    <LinkIconNavigation path={'/chapters'}>
                        <svg width="1.6rem" height="1.6rem" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="12" width="8" height="4" fill="#1F1F1F"/>
                            <rect y="6" width="16" height="4" fill="#1F1F1F"/>
                            <rect width="10" height="4" fill="#1F1F1F"/>
                        </svg>
                    </LinkIconNavigation>
                </div>
            </div>
            <div className={classes.navigation__aboutWrapper}>
                <LinkNavigation path={'/about'} title={'Moi ?'}/>
            </div>
        </header>
    );
}

export default Navigation;