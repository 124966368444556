import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    notFound__wrapper: {
        display: 'flex',
        justifyContent: 'center '
    },
    notFound__para: {
        fontFamily: 'Roboto',
        fontSize: '1.6rem'
    }
});

const NotFound = () => {
    const classes = useStyles();

    return(
        <div className={classes.notFound__wrapper}>
            <p className={classes.notFound__para}>La page souhaitée n’a pas étais trouvé...</p>
        </div>
    );
}

export default NotFound;