import { motion } from "framer-motion";

const animations = {
    inital: {opacity: 0, y: 50},
    animate: {opacity: 1, y: 0},
    exit: {opacity: 0, y: -50},
}

const TransitionPage = ({children}) => {
    return(
        <motion.div
            variants={animations}
            initial='inital'
            animate='animate'
            exit='exit'
            transition={{duration: 1}}
            style={{
                overflowY: "scroll",
                overflowX: 'hidden',
                height: "inherit"
            }}
        >
            {children}
        </motion.div>
    )
}

export default TransitionPage;