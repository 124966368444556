import { createUseStyles } from "react-jss";

// Atoms: 
import TransitionPage from "../components/atoms/TransitionPage/TransitionPage";
import PopinImg from "../components/atoms/PopinImg/PopinImg";

const useStyles = createUseStyles({
    gallery__wrapper: {
        padding: "1.6rem",
        height: '-webkit-fill-available'
    },
    gallery__galleryContent: {
        display: "flex",
        flexDirection: "column",
        gap: "9.6rem"
    },
    gallery__line: {

    },
    gallery__listLine: {
        display: "flex",
        justifyContent: "space-between",
    },
    gallery__elementList: {
        display: "flex",
        gap: "1.6rem"
    },
    gallery__imgWrapper: {
        width: "24rem",
    },
    gallery__elementImg: {
        width: "100%"
    },
    listLineTest: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '9.6rem 6.4rem',
        flexWrap: 'wrap'
    },
    gallery__elementListTest: {
        display: 'flex',
        gap: '1.6rem'
    },
    '@media (max-width: 720px)': {
        gallery__wrapper: {},
        gallery__galleryContent: {},
        gallery__line: {},
        gallery__listLine: {},
        gallery__elementList: {},
        gallery__imgWrapper: {},
        gallery__elementImg: {},
        listLineTest: {
            justifyContent: 'space-evenly'
        },
        gallery__elementListTest: {}
    }
});

const Gallery = ({data, handlePop}) => {
    const classes = useStyles();
    
    const getSection01 = (section) => {
        return(
            <ul className={classes.listLineTest}>
                {section?.map((chapter, key) => (
                    <>
                        {chapter['imgs']?.map((img, k) => (
                            <li key={k} className={classes.gallery__elementListTest}>
                                <PopinImg img={img?.picture} alt={img?.alt} handlePop={handlePop}/>
                            </li>
                        ))}
                    </>
                ))}
            </ul>
        )
    }

    return(
        <TransitionPage>
            <div className={classes.gallery__wrapper}>
                <div className={classes.gallery__galleryContent}>
                    {getSection01(data?.chapters)}
                </div>
            </div>
        </TransitionPage>
    )
}

export default Gallery;