import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from "react-router";
import { AnimatePresence } from "framer-motion";
import axios from 'axios';
import { motion } from 'framer-motion';

import './reset.css';
import './app.css';

// Molecules:
import Navigation from './components/molecules/Navigation/Navigation';
import Footer from './components/molecules/Footer/Footer';
import Loader from './components/molecules/Loader/Loader';

// Pages:
import Gallery from './pages/Gallery';
import Chapters from './pages/Chapters';
import About from './pages/About';
import Terms from './pages/Terms';
import NotFound from './pages/NotFound';

const routes = [
  { path: "/", name: "gallery", Component: Gallery },
  { path: '/chapters', name: 'chapters', Component: Chapters },
  { path: '/about', name: 'about', Component: About },
  { path: '/terms', name: 'terms', Component: Terms },
  { path: '*', name: 'notFound', Component: NotFound }
];

const App = () => {
  
  const location = useLocation();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    axios
    .get( 'data.json' )
    .then( res => {
      setData(res.data)
    })
    .catch( err => console.log( err ) )
  },[]);

  return (
    <div className="App" data-barba="container">
      <AnimatePresence>
        {loading && (
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Loader setLoading={setLoading}/>
          </motion.div>
        )}
        <Navigation/>
        <AnimatePresence exitBeforeEnter>
          <Routes key={location.pathname} location={location}>
            {routes.map(({path, name, Component}, key) => {
              const dataComp = data[key];
              return(
                <Route key={path} path={path} element={<Component data={dataComp}/>}/>
              )
              })}
          </Routes>
        </AnimatePresence>
        <Footer/>
      </AnimatePresence>
    </div>
  );
}

export default App;
