import { useState } from 'react';
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    popin__background: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, .5)'
    },
    popin__fullImg: {
        cursor: 'pointer',
        position: 'absolute',
        height: 'calc(100vh - 3.2rem)',
        left: '50%',
        top: '50%',
        transformOrigin: 'center',
        transform: 'translate(-50%, -50%)'
    },
    notPopin__wrapper: {
        height: props => props.gallery ? '34rem' : '34rem',
    },
    popin__wrapper: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, .7)',
        overflow: 'hidden',
        cursor: 'pointer'
    },
    notPopin__img: {
        cursor: 'pointer',
        height: props => props.gallery ? '34rem' : '34rem'
    },
    popin__img: {
        maxHeight: 'calc(100vh - 24.8rem)',
        maxWidth: 'calc(100vw - 49.6rem)',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    '@media (max-width: 720px)': {
        popin__background: {},
        popin__fullImg: {},
        notPopin__wrapper: {},
        popin__wrapper: {},
        notPopin__img: {},
        popin__img: {
            height: 'auto',
            width: 'calc(100vw - 3.2rem)',
            maxWidth: 'none'
        }
    }
});

const PopinImg = (props) => {
    const classes = useStyles(props);

    const [popin, setPopin] = useState(false);

    const handlePopin = () => {
        setPopin(!popin);
    }

    return(
        <>
            {popin ?
                <div className={classes.notPopin__wrapper}>
                    <div className={classes.popin__wrapper} onClick={handlePopin}>
                        <img src={props.img[0]} alt={props.alt} className={classes.popin__img} onClick={handlePopin}/>
                    </div>
                    <img src={props.img[1]} alt={props.alt} className={classes.notPopin__img}/>
                </div> :
                <div className={classes.notPopin__wrapper}>
                    <img src={props.img[1]} alt={props.alt} className={classes.notPopin__img} onClick={handlePopin}/>
                </div>
            }
        </>
    )
}

export default PopinImg;