import { createUseStyles } from "react-jss";
import { Link, useResolvedPath, useMatch } from "react-router-dom";
import { useEffect, useRef } from 'react';
import gsap from "gsap/all";

const useStyles = createUseStyles({
    linkIconNavigation__wrapper: {
        "& svg": {
            opacity: .5,
            transition: 'opacity 400ms'
        }
    },
    linkIconNavigation__wrapperActive: {
        "& svg": {
            opacity: 1,
            transition: 'opacity 400ms'

        }
    },
    '@media (max-width: 720px)': {
        linkIconNavigation__wrapper: {
            '& svg': {
                width: '2.4rem',
                height: '2.4rem'
            }
        },
        linkIconNavigation__wrapperActive: {
            '& svg': {
                width: '2.4rem',
                height: '2.4rem'
            }
        }
    }
});

const LinkIconNavigation = ({ children, path }) => {
    const classes = useStyles();

    let resolved = useResolvedPath(path);
    let match = useMatch({ path: resolved.pathname, end: true });

    let linkRef = useRef(null);
    useEffect(() => {
        gsap.from(linkRef, {
            opacity: 0,
            ease: 'power3.inOut',
            duration: 1.6,
            delay: .4
        })
    }, [])

    return(
        <Link to={path} className={ match ? classes.linkIconNavigation__wrapperActive : classes.linkIconNavigation__wrapper} ref={el => linkRef = el}>
            { children }
        </Link>
    );
};

export default LinkIconNavigation;