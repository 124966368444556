import { createUseStyles } from "react-jss";
import { AnimateSharedLayout } from "framer-motion";

// Atoms:
import TransitionPage from "../components/atoms/TransitionPage/TransitionPage";
import LinkChapterComp from "../components/atoms/LinkChapterComp/LinkChapterComp";

const useStyles = createUseStyles({
    chapters__wrapper: {
        padding: "1.6rem",
        height: '-webkit-fill-available',
        width: 'initial'
    },
    chapters__firstList: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "6.4rem"
    },
    chapter__firstElement: {},
    '@media (max-width: 720px)': {
        chapters__wrapper: {},
        chapters__firstList: {},
        chapter__firstElement: {}
    }
});

const Chapters = ({data}) => {
    const classes = useStyles();

    const getSection01 = (section) => {
        return(
            <AnimateSharedLayout>
                <ul layout className={classes.chapters__firstList}>
                    {section?.map((chapter, key) => (
                        <li className={classes.chapter__firstElement} key={key}>
                            <LinkChapterComp title={chapter?.title} para={chapter?.para} imgs={chapter?.imgs}/>
                        </li>
                    ))}
                </ul>
            </AnimateSharedLayout>
        )
    }

    return(
        <TransitionPage>
            <div className={classes.chapters__wrapper}>
                {getSection01(data?.chapters)}
            </div>
        </TransitionPage>
    )
}

export default Chapters;