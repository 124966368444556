import { useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import gsap from 'gsap';

const useStyles = createUseStyles({
    picture__wrapper: {
        width: "92rem",
        height: "51.7rem",
        position: 'relative'
    },
    picture__subWrapper: {
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center'
    },
    picture__img: {
        width: "92rem",
        height: "51.7rem"
    },
    '@media (max-width: 720px)': {
        picture__wrapper: {
            width: '100%'
        },
        picture__subWrapper: {

        },
        picture__img: {

        }
    }
});

const Picture = ({img, alt}) => {
    const classes = useStyles();

    let pictureRef = useRef(null);
    useEffect(() => {
        gsap.fromTo(pictureRef, {
            height: 0
        }, {
            height: '51.7rem',
            ease: 'power3.inOut',
            duration: 1.6
        })
    }, [])

    return(
        <div className={classes.picture__wrapper}>
            <div className={classes.picture__subWrapper} ref={el => pictureRef = el}>
                <img src={img} alt={alt} className={classes.picture__img}/>
            </div>
        </div>
    );
};

export default Picture;