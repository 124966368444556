import { useEffect, useRef } from 'react';
import { createUseStyles } from "react-jss";
import { Link } from 'react-router-dom';
import gsap from 'gsap';

const useStyles = createUseStyles({
    h3Title__link: {
        overflow: 'hidden',
        transition: 'transform 400ms',
        '&:hover': {
            transform: 'scale(1.02)'
        }
    },
    h3Title__wrapper: {
    },
    h3Title__titleLetter: {
        position: 'relative',
        fontSize: '12.8rem',
        fontFamily: 'Mirage',
        fontWeight: '500',
        color: "#1F1F1F",
    },
    '@media (max-width: 720px)': {
        h3Title__link: {

        },
        h3Title__wrapper: {

        },
        h3Title__titleLetter: {
            fontSize: '7rem',
        }
    }
});

const H3Title = ({ title, link, path }) => {
    const classes = useStyles();

    const lettersRef = useRef(null);
    useEffect(() => {
        gsap.from([].slice.call(lettersRef?.current?.children), {
            top: '6rem',
            ease: 'power3.inOut',
            duration: 1.6,
            stagger: {
                amount: .3
            },
        });
        gsap.from([].slice.call(lettersRef?.current?.children), {
            opacity: 0,
            ease: 'power3.inOut',
            duration: 1.6,
            stagger: {
                amount: .6
            }
        })
    }, [])

    const splitTitle = (el) => {
        const splitEl = el?.split('');
        return(
            <>
                {splitEl?.map((letter, key) => (
                    <span className={classes.h3Title__titleLetter} key={key}>
                        {letter}
                    </span>
                ))}
            </>
        )
    }

    return(
        <>
            {link ?
                <Link to={path} className={classes.h3Title__link}>
                    <h3 className={classes.h3Title__wrapper} ref={lettersRef}>
                        {splitTitle(title)}
                    </h3>
                </Link> :
                <h3 className={classes.h3Title__wrapper} ref={lettersRef}>
                    {splitTitle(title)}
                </h3>
            }
        </>
    )
}

export default H3Title;