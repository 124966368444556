import { useState } from 'react';
import { createUseStyles } from "react-jss";
import { motion, AnimatePresence } from 'framer-motion';

// Atoms:
import PopinImg from '../PopinImg/PopinImg';

const animations = {
    inital: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0}
}

const useStyles = createUseStyles({
    linkChapterComp__wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1.6rem'
    },
    linkChapterComp__title: {
        fontSize: '9.5rem',
        fontFamily: 'Mirage',
        cursor: 'pointer',
        color: '#1F1F1F',
        textAlign: 'center',
        transition: 'transform 400ms',
        '&:hover': {
            transform: 'scale(1.02)'
        }
    },
    linkChapterComp__showContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1.6rem',
    },
    linkChapterComp__hiddenContent: {
        display: 'none'
    },
    linkChapterComp__paraWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '.8rem'
    },
    linkChapterComp__para: {
        color: '#1F1F1F',
        textAlign: 'center',
        width: '92rem',
        fontSize: '1.6rem',
        fontFamily: 'Roboto'
    },
    linkChapterComp__carouselWrapper: {
        display: 'flex',
        gap: '1.6rem',
        width: 'calc(100vw - 3.2rem)',
        overflow: 'auto',
        paddingBottom: '.8rem'
    },
    linkChapterComp__imgWrapper: {
        width: 'auto',
        height: '100%'
    },
    linkChapterComp__img: {},
    '@media (max-width: 720px)': {
        linkChapterComp__wrapper: {},
        linkChapterComp__title: {},
        linkChapterComp__showContent: {},
        linkChapterComp__hiddenContent: {},
        linkChapterComp__paraWrapper: {},
        linkChapterComp__para: {
            width: 'auto',
            padding: '0 2rem',
            fontSize: '2.4rem',
        },
        linkChapterComp__carouselWrapper: {},
        linkChapterComp__imgWrapper: {},
        linkChapterComp__img: {}
    }
});

const LinkChapterComp = ({title, para, imgs}) => {
    const classes = useStyles();

    const [revealSwitch, setRevealSwitch] = useState(false);

    const revealContent = () => {
        setRevealSwitch(!revealSwitch);
    }

    const Content = () => {
        return(
            <motion.div
                variants={animations}
                initial='inital'
                animate='animate'
                exit='exit'
                transition={{duration: .4}}
                className={classes.linkChapterComp__showContent}
            >
                <div className={classes.linkChapterComp__paraWrapper}>
                    {para?.map((p, key) => (
                        <p key={key} className={classes.linkChapterComp__para}>{p}</p>
                    ))}
                </div>
                <div className={classes.linkChapterComp__carouselWrapper}>
                    {imgs?.map(({picture, alt}, key) => (
                        <div key={key} className={classes.linkChapterComp__imgWrapper}>
                            <PopinImg img={picture} alt={alt} chapters/>
                        </div>
                    ))}
                </div>
            </motion.div>
        )
    }

    return(
        <motion.div className={classes.linkChapterComp__wrapper}>
            <h3 className={classes.linkChapterComp__title} onClick={revealContent}>{title}</h3>
            <AnimatePresence>
                {revealSwitch && Content()}
            </AnimatePresence>
        </motion.div>
    )
}

export default LinkChapterComp;