import { createUseStyles } from "react-jss";

// Atoms:
import TransitionPage from './../components/atoms/TransitionPage/TransitionPage';

const useStyles = createUseStyles({
    terms__wrapper: {
        padding: "6.4rem",
        height: '-webkit-fill-available',
        display: "flex",
        gap: '6.4rem'
    },
    terms__sectionWrapper: {
        flexDirection: 'column',
        display: 'flex',
        gap: '3.2rem',
        width: '100%'
    },
    terms__title: {
        fontFamily: 'Mirage',
        fontWeight: '600',
        fontSize: '2.4rem'
    },
    terms__paraWrapper: {
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.6rem'
    },
    terms__para: {
        fontFamily: 'Roboto',
        fontSize: '1.6rem'
    },
    '@media (max-width: 720px)': {
        terms__wrapper: {
            flexDirection: 'column'
        },
        terms__sectionWrapper: {
            maxHeight: '90rem'
        },
        terms__title: {

        },
        terms__paraWrapper: {

        },
        terms__para: {

        }
    }
});

const Terms = ({data}) => {
    const classes = useStyles();

    const allRightsReserved = () => {
        return(
            <p className={classes.terms__para}>{`Tous droits réservés - ${(new Date().getFullYear())}`}</p>
        )
    }

    const getPara = (section) => {
        return(
            <>
                {section?.map((para, key) => (
                    <p className={classes.terms__para} key={key}>{para}</p>
                ))}
            </>
        )
    }

    return(
        <TransitionPage>
            <div className={classes.terms__wrapper}>
                {data?.terms?.map((section, key) => (
                    <div className={classes.terms__sectionWrapper} key={key}>
                        <h3 className={classes.terms__title}>{section?.title}</h3>
                        <div className={classes.terms__paraWrapper}>
                            {getPara(section?.para)}
                            {allRightsReserved()}
                        </div>
                    </div>
                ))}
            </div>
        </TransitionPage>
    );
}

export default Terms;