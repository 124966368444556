import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { useRef, useEffect } from 'react';
import gsap from 'gsap';

const useStyles = createUseStyles({
    titlePage__wrapper: {
        color: '#1F1F1F',
        textDecoration: 'none',
    },
    titlePage__content: {
        display: 'flex',
        gap: '0.8rem',
    },
    titlePage__logoWrapper: {
        height: '1.6rem',
        width: '1.6rem'
    },
    titlePage__logoImg: {
        width: '1.6rem'
    },
    titlePage__titleWrapper: {
    },
    titlePage__title: {
    },
    titlePage__titleLetter: {
        position: 'relative',
        fontSize: '1.6rem',
        fontFamily: 'Mirage',
        fontWeight: '600'
    },
    '@media (max-width: 720px)': {
        titlePage__wrapper: {
        },
        titlePage__content: {
            alignItems: 'center'
        },
        titlePage__logoWrapper: {
            height: '2.4rem',
            width: '2.4rem'
        },
        titlePage__logoImg: {
            width: '2.4rem'
        },
        titlePage__titleWrapper: {
        },
        titlePage__title: {
        },
        titlePage__titleLetter: {
            fontSize: '2rem'
        },
    }
});

const TitlePage = ({path, logo, title}) => {
    const classes = useStyles();

    let logoRef = useRef(null);
    const lettersRef = useRef(null)
    useEffect(() => {
        gsap.from(lettersRef.current.children, {
            top: '1.6rem',
            ease: 'power3.inOut',
            duration: 1.6,
            delay: .4,
            stagger: {
                amount: .3
            },
        });
        gsap.from(lettersRef.current.children, {
            opacity: 0,
            ease: 'power3.inOut',
            duration: 1.6,
            delay: .6,
            stagger: {
                amount: .6
            }
        })
        gsap.from(logoRef, {
            opacity: 0,
            ease: 'power3.inOut',
            duration: 1.6,
            delay: .4
        });
    }, [])

    const splitTitle = (el) => {
        const splitEl = el.split('');
        return(
            <>
                {splitEl.map((letter, key) => (
                    <span className={classes.titlePage__titleLetter} key={key}>
                        {letter}
                    </span>
                ))}
            </>
        )
    }

    return(
        <Link to={path} className={classes.titlePage__wrapper}>
            <div className={classes.titlePage__content}>
                <div className={classes.titlePage__logoWrapper} ref={el => logoRef = el}>
                    <img src={logo} alt={"Logo Maurine Lamotte"} className={classes.titlePage__logoImg}/>
                </div>
                <div className={classes.titlePage__titleWrapper}>
                    <h1 className={classes.titlePage__title} ref={lettersRef}>
                        {splitTitle(title)}
                    </h1>
                </div>
            </div>
        </Link>
    )
}

export default TitlePage;