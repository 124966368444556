import { useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import gsap from 'gsap';

const useStyles = createUseStyles({
    titleLoading__wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '.8rem'
    },
    titleLoading__imgWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '2rem',
        height: '2rem'
    },
    titleLoading__imgSubWrapper: {
        height: '1.6rem',
        width: '1.6rem'
    },
    titleLoading__img: {
        width: '100%'
    },
    titleLoading__titleWrapper: {

    },
    titleLoading__title: {
    },
    titlePage__titleLetter: {
        position: 'relative',
        fontSize: '1.6rem',
        fontFamily: 'Mirage',
        fontWeight: '600'
    }
});

const TitleLoading = ({logo, title}) => {
    const classes = useStyles();

    let logoRef = useRef(null);
    const lettersRef = useRef(null);
    useEffect(() => {
        gsap.from(logoRef, {
            opacity: 0,
            ease: 'power3.inOut',
            duration: 1,
            delay: .4
        })
        gsap.from(logoRef, {
            width: '2rem',
            height: '2rem',
            duration: 1,
            ease: 'power3.out',
            repeat: -1,
            yoyo:true
        })

        gsap.from(lettersRef.current.children, {
            opacity: 0,
            ease: 'power3.inOut',
            duration: 1,
            delay: .6,
            stagger: {
                amount: .6
            }
        })
        gsap.from(lettersRef.current.children, {
            top: '1.6rem',
            ease: 'power3.inOut',
            duration: 1.6,
            delay: .4,
            stagger: {
                amount: .3
            },
        });
    }, []);

    const splitTitle = (el) => {
        const splitEl = el.split('');
        return(
            <>
                {splitEl.map((letter, key) => (
                    <span className={classes.titlePage__titleLetter} key={key}>
                        {letter}
                    </span>
                ))}
            </>
        )
    }

    return(
        <div className={classes.titleLoading__wrapper}>
            <div className={classes.titleLoading__imgWrapper}>
                <div className={classes.titleLoading__imgSubWrapper} ref={el => logoRef = el}>
                    <img src={logo} alt={"Logo Maurine Lamotte"} className={classes.titleLoading__img}/>
                </div>
            </div>
            <div className={classes.titleLoading__titleWrapper}>
                <h1 className={classes.titleLoading__title} ref={lettersRef}>{splitTitle(title)}</h1>
            </div>
        </div>
    )
}

export default TitleLoading;