import { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { motion } from 'framer-motion';

import logo from '../../../assets/icons/logo.png';

// Atoms:
import TitleLoading from './../../atoms/TitleLoading/TitleLoading';

const animations = {
    inital: {opacity: 1, y: 0},
    animate: {opacity: 1, y: 0},
    exit: {opacity: 0, y: -50},
}

const useStyles = createUseStyles({
    loader__wrapper: {
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'white',
        zIndex: 5000
    },
    loader__inner: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

const Loader = ({setLoading}) => {
    const classes = useStyles();
    
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 6000);
        return () => clearTimeout(timer);   
    }, [setLoading])

    return(
        <motion.div
            className={classes.loader__wrapper}
            variants={animations}
            initial='inital'
            animate='animate'
            exit='exit'
            transition={{duration: 1, delay: 1}}
        >
            <div className={classes.loader__inner}>
                <TitleLoading logo={logo} title={'Maurine Lamotte'}/>
            </div>
        </motion.div>
    );
};

export default Loader;