import { useEffect, useRef } from 'react';
import { createUseStyles } from "react-jss";
import { Link, useResolvedPath, useMatch } from "react-router-dom";
import gsap from 'gsap';

const useStyles = createUseStyles({
    linkNavigation__wrapper: {
        opacity: .5,
        transition: 'opacity 400ms'
    },
    linkNavigation__wrapperActif: {
        opacity: 1,
        transition: 'opacity 400ms'
    },
    linkNavigation__h2: {
        fontSize: '1.6rem',
        fontFamily: 'Mirage',
        fontWeight: '500'
    },
    '@media (max-width: 720px)': {
        linkNavigation__wrapper: {
        },
        linkNavigation__wrapperActif: {
        },
        linkNavigation__h2: {
            fontSize: '2.4rem',
        },
    }
});

const LinkNavigation = ({path, title}) => {
    const classes = useStyles();

    let resolved = useResolvedPath(path);
    let match = useMatch({ path: resolved.pathname, end: true });

    let titleRef = useRef(null);
    useEffect(() => {
        gsap.from(titleRef, {
            opacity: 0,
            ease: 'power3.inOut',
            duration: 1.6,
            delay: .4
        })
    }, []);

    return(
        <Link to={path} className={match ? classes.linkNavigation__wrapperActif : classes.linkNavigation__wrapper}>
            <h2 className={classes.linkNavigation__h2} ref={el => titleRef = el}>
                {title}
            </h2>
        </Link>
    );
};

export default LinkNavigation;